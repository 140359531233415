import React from "react";
import Grid from "@mui/material/Grid2";
import {
  FaLinkedin,
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaTiktok,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./FooterStyle.css";

const Footer = ({ t }) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="footer-container">
        {/* Footer Links */}
        <Grid container spacing={5} justifyContent="center">
          <Grid size={{ xs: 12, sm: 12, md: 3 }} className="footer-section">
            <Link to="/">
              <div className="address">
                <img src="/logo-historiar-light.png" alt="logo historiar" />
                <h4>HISTORIAR</h4>
              </div>
            </Link>
          </Grid>

          {/* Policy and terms Section */}
          <Grid size={{ xs: 12, sm: 4, md: 3 }} className="footer-section">
            <h4>{t("policy_and_terms")}</h4>
            <ul>
              <li>
                <Link to="/privacy-policy">{t("privacy_policy")}</Link>
              </li>
            </ul>
          </Grid>

          {/* Social Section */}
          <Grid size={{ xs: 12, sm: 4, md: 3 }} className="footer-section">
            <h4>{t("social")}</h4>
            <div className="social-icons">
              <Link
                to="https://www.facebook.com/p/HISTORIAR-100063614228567/"
                target="_blank"
                className="icon"
              >
                <FaFacebook size={30} />
              </Link>
              <Link
                to="https://twitter.com/HistoriarSa"
                target="_blank"
                className="icon"
              >
                <FaTwitter size={30} />
              </Link>
              <Link
                to="https://www.linkedin.com/company/historiar/"
                target="_blank"
                className="icon"
              >
                <FaLinkedin size={30} />
              </Link>
              <Link
                to="https://www.instagram.com/historiar.io/"
                target="_blank"
                className="icon"
              >
                <FaInstagram size={30} />
              </Link>
              <Link
                to="https://www.tiktok.com/@historiar.io"
                target="_blank"
                className="icon"
              >
                <FaTiktok size={30} />
              </Link>
            </div>
          </Grid>

          {/* Get in touch Section */}
          <Grid size={{ xs: 12, sm: 4, md: 3 }} className="footer-section">
            <h4>{t("get_in_touch")}</h4>
            <ul>
              <li>
                <Link to="mailto: contact@historiar.io">
                  contact@historiar.io
                </Link>
              </li>
              <li>
                <Link to="tel:+21622585448">+216 22 585 448</Link>
              </li>
            </ul>
          </Grid>
        </Grid>

        {/* Footer Copyright */}
        <p>HISTORIAR © {currentYear}</p>
      </div>
    </div>
  );
};

export default Footer;
