import { useEffect, useState } from "react";
import { Parallax } from "react-scroll-parallax";
import Slider from "../Common/Slider";
import "./ParallaxAccueilStyle.css";

const ParallaxAccueil = ({ t, data }) => {
  const [filteredData, setFilteredData] = useState(data);
  const [nbSites, setNbSites] = useState(0);

  const countAvailableSites = () => {
    let count = 0;
    data.forEach((site) => {
      if (site.sites) {
        for (const era in site.sites) {
          site.sites[era].forEach((item) => {
            if (!item.comingSoon) {
              count++;
            }
          });
        }
      }
    });

    return count;
  };

  useEffect(() => {
    const filtered = data.filter(({ title }) => title.toLowerCase());
    const nb = countAvailableSites();

    setFilteredData(filtered);
    setNbSites(nb);
  }, [data]);

  return (
    <Parallax speed={-40} className="parallax-container">
      <div className="slider-accueil">
        <h1
          className="title-accueil"
          style={{
            direction: t("lang") === "ar" ? "rtl" : "ltr",
            color: "#f9db1f",
            textTransform: "uppercase",
          }}
        >
          {t("xr_experiences")}{" "}
          <span className="span-accueil">
            {nbSites + " " + t("vr_ar_visits")}
          </span>
        </h1>
        <br />
        <h2
          className="title-accueil"
          style={{ direction: t("lang") === "ar" ? "rtl" : "ltr" }}
        >
          {t("archeological_sites")}
        </h2>
        <Slider data={filteredData} />
      </div>
    </Parallax>
  );
};

export default ParallaxAccueil;
